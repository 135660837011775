<template>

        <div class="spinner-border" role="status"></div>
   
</template>

<script>

export default {
    name: 'AppLoader',
    components: {
    },
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        rows: {
            type: Number,
            default: 3
        },
        rowHeight: {
            type: String,
            default: '20px'
        }
    }
};
</script>
